<template>
  <div class="align">
    <div id="menuPageWrap" class="contentBlock">
      <ul class="body">
        <li v-for="menuLink in menuList" :class="{active:menuLink.isLastPage}">
          <router-link  :to="menuLink.path">
            <span>{{menuLink.name}}</span>
            <IconBase :viewBox="'0 0 5 10'">
              <menuRight></menuRight>
            </IconBase>
          </router-link>
        </li>
        <li>
          <router-link  :to="'/kl/ChangePassword'">
            <span>Изменить пароль</span>
            <IconBase :viewBox="'0 0 5 10'">
              <menuRight></menuRight>
            </IconBase>
          </router-link>
        </li>
        <li>
          <router-link  :to="'/logOut'">
            <span>Выход</span>
            <IconBase :viewBox="'0 0 5 10'">
              <menuRight></menuRight>
            </IconBase>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "menuPage",
    data() {
      return {
        menuList: [],
        lastPageVisit:null,
      }
    },
    methods:{
      activeMenu(){
        return false;
      },
    },
    components: {
      IconBase: () => import('@/components/svg/IconBase'),
      menuRight: () => import('@/components/svg/menuRight')
    },
    created() {
      //смотрим на роутер и тащим все необходимые ссылки
      this.$router.options.routes.forEach(route => {
        if (typeof route.menuView === 'function') {
          route.menuView(route)
        }
        if (route.menuView == true) {
          this.menuList.push({
            name: route.name,
            path: route.path,
            isLastPage:false
          })
        }
      });
    },
    watch:{
      lastPageVisit(){
        //как только стало известно о прошлой странице.
        this.menuList.forEach((element, index) =>{
          this.menuList[index].isLastPage = this.lastPageVisit.path == element.path;
        });
      }
    },
    beforeRouteEnter(to, from, next) {
      //смотрим на последнюю страницу в роутере, https://stackoverflow.com/questions/53788975/vue-router-how-to-get-previous-page-url/53789212
      next((vm) => {
        vm.lastPageVisit = from;
      });
    },
    mounted() {
      this.$parent.selectedSection = 0
      window.scrollTo(0, 0);

      this.$myApi.checkVersion();
    }
  }
</script>

<style scoped>
  ul{}
  li{position: relative;}
  /*li:after{content: '';position: absolute;bottom: 0;left:calc(50% - 15%);width: 30%;height:1px;background: #0f5740;}*/
  a{text-decoration: none;padding: 3vw 0;display: flex;align-content: center;justify-content: space-between;color:#0f5740;}
  li.active a{color:#3FAE2A}
  a span{}
  a svg{width: 3vw;height:3vw;margin-right: 2vw}
  li.active svg{fill:#3FAE2A;transform: rotate(90deg);margin-right: 3vw}
  @media screen and (min-width: 760px){
    #menuPageWrap{height: calc(100vh - 25vw);display: flex;align-items: center;flex-direction: column;justify-content: center}
    ul{width: 40vw;}
    li{}
    a{padding: 1.5vw 0}
    a span{}
    a svg{width: 2vw;height: 2vw;}
    li.active svg{margin-right: 2.5vw}
  }
  @media screen and (min-width: 1200px){
    #menuPageWrap{height: auto;}
    ul{width: 400px;}
    li{}
    a{padding: 10px 0}
    a span{}
    a svg{width: 10px;height: 10px;margin-right: 5px}
    li.active svg{margin-right: 5px}
  }
</style>
